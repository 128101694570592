import Link from 'next/link'

import { Button } from '@/components/elements/buttons/button'
import WrapperMaxWidth from '@/components/elements/wrapperMaxWidth'
import Header from '@/components/shared/header'

const defaultTitle = 'Something went wrong'
const defaultSubtitle = 'Sorry, we could not find the page you are looking for.'

const ErrorPage = ({ title = defaultTitle, subtitle = defaultSubtitle }) => {
  return (
    <>
      <Header />
      <WrapperMaxWidth>
        <div className="error-container">
          <h1 className="error-title">{title}</h1>
          <p className="error-text">{subtitle} </p>
          <Link href="/">
            <Button text="Go to Home" className="error-button" />
          </Link>
        </div>
      </WrapperMaxWidth>
    </>
  )
}
export default ErrorPage
