export const HeaderBackground = ({ className }) => (
  <div className={className}>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      width="100%"
      height="100%"
      viewBox="0 0 3000 2000"
    >
      <g transform="translate(1500,1000) scale(1,1) translate(-1500,-1000)">
        <path
          d="M 2072 1000 C 2072 1072 1967 1215 1928 1275 C 1889 1335 1726 1323 1661 1353 C 1596 1383 1509 1440 1438 1430 C 1367 1420 1198 1458 1144 1411 C 1090 1364 1010 1219 990 1150 C 970 1081 994 926 1014 857 C 1034 788 1167 725 1221 678 C 1275 631 1374 626 1445 616 C 1516 606 1604 601 1669 631 C 1734 661 1913 650 1952 710 C 1991 770 2072 928 2072 1000"
          fill="#add6ff"
          strokeWidth="0"
          opacity="0.8"
        >
          <animate
            attributeName="d"
            dur="30s"
            repeatCount="indefinite"
            keyTimes="0;0.3333333333333333;0.6666666666666666;1"
            calcmod="spline"
            keySplines="0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9"
            begin="-22.222222222222225s"
            values="M 2015 1000 C 2015 1112 1851 1260 1763 1330 C 1675 1400 1526 1390 1417 1365 C 1308 1340 1061 1336 1012 1235 C 963 1134 946 858 995 757 C 1044 656 1297 614 1406 589 C 1515 564 1701 569 1788 639 C 1875 709 2015 888 2015 1000;M 2109 1000 C 2109 1117 1916 1334 1824 1407 C 1732 1480 1534 1376 1420 1350 C 1306 1324 997 1372 946 1267 C 895 1162 953 866 1004 761 C 1055 656 1308 683 1422 657 C 1536 631 1687 579 1778 652 C 1869 725 2109 883 2109 1000;M 2107 1000 C 2107 1117 1878 1286 1786 1359 C 1694 1432 1511 1476 1397 1450 C 1283 1424 1007 1367 956 1262 C 905 1157 933 857 984 752 C 1035 647 1281 566 1395 540 C 1509 514 1763 483 1854 556 C 1945 629 2107 883 2107 1000;M 2015 1000 C 2015 1112 1851 1260 1763 1330 C 1675 1400 1526 1390 1417 1365 C 1308 1340 1061 1336 1012 1235 C 963 1134 946 858 995 757 C 1044 656 1297 614 1406 589 C 1515 564 1701 569 1788 639 C 1875 709 2015 888 2015 1000"
          ></animate>
        </path>
        <path
          d="M 2113 1000 C 2113 1077 2076 1278 2034 1343 C 1992 1408 1791 1452 1721 1484 C 1651 1516 1512 1456 1436 1445 C 1360 1434 1242 1416 1184 1365 C 1126 1314 987 1231 965 1157 C 943 1083 857 892 879 818 C 901 744 1136 698 1194 647 C 1252 596 1370 634 1446 623 C 1522 612 1638 512 1708 544 C 1778 576 1908 646 1950 711 C 1992 776 2113 923 2113 1000"
          fill="#5c89d1"
          strokeWidth="0"
          opacity="0.8"
        >
          <animate
            attributeName="d"
            dur="30s"
            repeatCount="indefinite"
            keyTimes="0;0.3333333333333333;0.6666666666666666;1"
            calcmod="spline"
            keySplines="0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9"
            begin="-22.222222222222225s"
            values="M 2027 1000 C 2027 1113 1847 1254 1759 1325 C 1671 1396 1535 1356 1425 1331 C 1315 1306 1052 1341 1003 1239 C 954 1137 966 869 1015 767 C 1064 665 1305 650 1415 625 C 1525 600 1724 537 1813 607 C 1902 677 2027 887 2027 1000;M 2127 1000 C 2127 1115 1889 1303 1799 1375 C 1709 1447 1514 1456 1402 1430 C 1290 1404 1103 1319 1053 1215 C 1003 1111 916 847 966 743 C 1016 639 1300 640 1412 614 C 1524 588 1708 554 1798 626 C 1888 698 2127 885 2127 1000;M 2047 1000 C 2047 1121 1893 1299 1798 1374 C 1703 1449 1533 1399 1415 1372 C 1297 1345 1008 1372 955 1263 C 902 1154 870 831 922 722 C 974 613 1276 564 1394 537 C 1512 510 1770 467 1865 542 C 1960 617 2047 879 2047 1000;M 2027 1000 C 2027 1113 1847 1254 1759 1325 C 1671 1396 1535 1356 1425 1331 C 1315 1306 1052 1341 1003 1239 C 954 1137 966 869 1015 767 C 1064 665 1305 650 1415 625 C 1525 600 1724 537 1813 607 C 1902 677 2027 887 2027 1000"
          ></animate>
        </path>
        <path
          d="M 2178 1000 C 2178 1086 2119 1296 2073 1368 C 2027 1440 1785 1418 1707 1454 C 1629 1490 1513 1512 1428 1500 C 1343 1488 1207 1469 1142 1413 C 1077 1357 959 1248 935 1166 C 911 1084 900 913 924 831 C 948 749 1062 626 1127 570 C 1192 514 1346 529 1431 517 C 1516 505 1653 459 1731 495 C 1809 531 1930 622 1976 694 C 2022 766 2178 914 2178 1000"
          fill="#183e9e"
          strokeWidth="0"
          opacity="0.8"
        >
          <animate
            attributeName="d"
            dur="30s"
            repeatCount="indefinite"
            keyTimes="0;0.3333333333333333;0.6666666666666666;1"
            calcmod="spline"
            keySplines="0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9;0.3 0.1 0.7 0.9"
            begin="-22.222222222222225s"
            values="M 2055 1000 C 2055 1114 1848 1254 1759 1325 C 1670 1396 1526 1398 1415 1373 C 1304 1348 1096 1322 1046 1219 C 996 1116 1021 896 1071 793 C 1121 690 1296 616 1407 591 C 1518 566 1713 550 1802 621 C 1891 692 2055 886 2055 1000;M 2105 1000 C 2105 1116 1887 1299 1796 1371 C 1705 1443 1510 1478 1397 1452 C 1284 1426 1083 1330 1033 1225 C 983 1120 898 839 949 734 C 1000 629 1302 652 1415 626 C 1528 600 1698 565 1789 637 C 1880 709 2105 884 2105 1000;M 2083 1000 C 2083 1113 1913 1338 1825 1408 C 1737 1478 1523 1406 1413 1381 C 1303 1356 1098 1318 1049 1217 C 1000 1116 962 866 1011 765 C 1060 664 1314 692 1424 667 C 1534 642 1667 610 1755 680 C 1843 750 2083 887 2083 1000;M 2055 1000 C 2055 1114 1848 1254 1759 1325 C 1670 1396 1526 1398 1415 1373 C 1304 1348 1096 1322 1046 1219 C 996 1116 1021 896 1071 793 C 1121 690 1296 616 1407 591 C 1518 566 1713 550 1802 621 C 1891 692 2055 886 2055 1000"
          ></animate>
        </path>
      </g>
    </svg>
  </div>
)
