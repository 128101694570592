import { HeaderBackground } from '../elements/headerBackground'

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="header-background-container">
        <HeaderBackground className="header-background" />
      </div>
    </div>
  )
}

export default Header
