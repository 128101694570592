import classNames from 'classnames'

export const Button = ({ text, className, onClick, disabled }) => {
  const classes = classNames('button-container', className)
  return (
    <button type="button" className={classes} onClick={onClick} disabled={disabled}>
      <div className="button-label">{text}</div>
    </button>
  )
}
