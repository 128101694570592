import { useRouter } from 'next/dist/client/router'
import { getPageData } from 'utils/api'
import { getStrapiURL } from 'utils/endpoints'

import Seo from '@/components/elements/seo'
import Sections from '@/components/sections/sections'
import ErrorPage from '@/components/shared/error'

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const DynamicPage = ({ sections, preview, isMobileType, global }) => {
  const router = useRouter()

  // Check if the required data was provided
  if (!router.isFallback && !sections?.length) {
    return <ErrorPage />
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>
  }

  // Extract metadata from global
  const { metadata } = global

  return (
    <>
      <Seo metadata={metadata} />
      <Sections
        sections={sections}
        preview={preview}
        isMobileType={isMobileType}
        global={global}
      />
    </>
  )
}

export async function getStaticPaths() {
  // Get all pages from Strapi
  const pages = await (await fetch(getStrapiURL('/pages'))).json()
  const paths = pages.data.map(({ attributes: page }) => {
    // Decompose the slug that was saved in Strapi
    const slugArray = page.slug.split('__')
    slugArray.push('')
    return {
      params: { slug: slugArray }
    }
  })
  return { paths, fallback: true }
}

export async function getStaticProps({ params, preview = null }) {
  // Find the page data for the current slug
  let chainedSlugs
  chainedSlugs = params == {} || !params.slug ? 'home' : params.slug.join('__')
  // Fetch pages. Include drafts if preview mode is on
  const pageData = await getPageData(chainedSlugs, preview)

  if (!pageData) {
    // Giving the page no props will trigger a 404 page
    return { props: {} }
  }

  // We have the required page data, pass it to the page component
  const { contentSections, metadata } = pageData.data[0].attributes

  return {
    props: {
      preview,
      sections: contentSections,
      metadata
    },
    revalidate: 360
  }
}

export default DynamicPage
