import dynamic from 'next/dynamic'
import { Fragment, memo } from 'react'

import WrapperMaxWidth from '../elements/wrapperMaxWidth'

const Benefits = dynamic(() => import('./career/benefits'))
const Contact = dynamic(() => import('./contact/contact'))
const DualPictures = dynamic(() => import('../shared/dualPictures'))
const Header = dynamic(() => import('../shared/header'))
const HeaderHomePage = dynamic(() => import('./home/headerHomePage'))
const Initiatives = dynamic(() => import('../shared/initiatives'))
const InnovationAwards = dynamic(() => import('./about/innovationAwards'))
const JobOffers = dynamic(() => import('./career/jobOffers'))
const HomeLatestProjects = dynamic(() => import('./home/latestProjects'))
const LatestSeminars = dynamic(() => import('./home/latestSeminars'))
const LifeInCeltiberian = dynamic(() => import('./about/lifeInCeltiberian'))
const MeetTheTeam = dynamic(() => import('./about/meetTheTeam'))
const OurCulture = dynamic(() => import('./about/ourCulture'))
const ProjectsList = dynamic(() => import('./projects/projectsList'))
const SeminarsList = dynamic(() => import('./seminars/seminarsList'))
const WhatWeDo = dynamic(() => import('./home/whatWeDo'))
const WhoWeAre = dynamic(() => import('./about/whoWeAre'))
const LegalDocument = dynamic(() => import('./legal/legalDocument'))
const TeamMap = dynamic(() => import('./about/teamMap'))

// Map Strapi sections to section components
const sectionComponents = {
  'sections.benefits': Benefits,
  'sections.contact-form': Contact,
  'sections.dual-images': DualPictures,
  'sections.header': Header,
  'sections.header-home-page': HeaderHomePage,
  'sections.initiatives': Initiatives,
  'sections.innovation-awards': InnovationAwards,
  'sections.job-offers': JobOffers,
  'sections.latest-projects': HomeLatestProjects,
  'sections.our-seminars': LatestSeminars,
  'sections.life-in-celtiberian': LifeInCeltiberian,
  'sections.meet-the-team': MeetTheTeam,
  'sections.our-culture': OurCulture,
  'sections.projects-list': ProjectsList,
  'sections.seminars-list': SeminarsList,
  'sections.what-we-do': WhatWeDo,
  'sections.who-we-are': WhoWeAre,
  'sections.legal-document': LegalDocument,
  'sections.team-map': TeamMap
}

// Display a section individually
const Section = ({ sectionData, global }) => {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__component]
  if (!SectionComponent) {
    return null
  }

  // TODO: @saul - find better way to add header
  const { background } = sectionData
  const isHeader = !background?.id
  const Wrapper = isHeader ? Fragment : WrapperMaxWidth
  return (
    <Wrapper>
      <SectionComponent data={sectionData} global={global} />
    </Wrapper>
  )
}

// Display the list of sections
const Sections = ({ sections, preview, isMobileType, global }) => {
  return (
    <>
      {/* Show a banner if preview mode is on */}
      {preview && <div>Preview mode is on</div>}
      {/* Show the actual sections */}
      {sections.map(section => {
        section.isMobileType = isMobileType
        return (
          <Section
            sectionData={section}
            key={`${section.__component}${section.id}`}
            global={global}
          />
        )
      })}
    </>
  )
}

export default memo(Sections)
