import { memo } from 'react'

import CustomImage from './images/image'

const renderBackground = ({
  image,
  imagePosition,
  useGradient,
  startColor,
  startPercentage,
  endColor,
  endPercentage,
  gradientOrientation
}) => {
  if (!image.data && !useGradient) return null
  return (
    <>
      {image.data && (
        <div className={`img-container ${imagePosition}`}>
          <CustomImage
            media={image.data.attributes}
            height={image.data.attributes.height}
            width={image.data.attributes.width}
            alt={image.data.attributes.alternativeText}
          />
        </div>
      )}
      {useGradient && (
        <div
          className="bg-gradient"
          style={{
            background: `linear-gradient(${gradientOrientation}deg, ${startColor} ${startPercentage}%, ${endColor} ${endPercentage}%
  )`
          }}
        />
      )}
    </>
  )
}

const WrapperMaxWidth = ({ children }) => {
  const { background } = children.props?.data ?? {}
  return (
    <div className="bg-container">
      {background && renderBackground(background)}
      <div className="wrapper-max-width">{children}</div>
    </div>
  )
}

export default memo(WrapperMaxWidth)
